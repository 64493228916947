import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/all";
// Splitting.js
// Calling the Splitting function to split the text into individual words/characters, 
Splitting();

// .content__text elements
let texts = document.querySelector('.content__text')
let texts2 = document.getElementById('our-manıfest')

let word = texts.querySelectorAll('.word');
let word2 = texts2.querySelectorAll('.word');

// switch between textsdd
export class switchTexts {
    constructor(){
        gsap.registerPlugin(ScrollTrigger);
        gsap.timeline({
            defaults: {
                duration: 1.2,
                ease: 'power4.inOut'
            },
            scrollTrigger: {
                trigger: ".about-cont",
                start: "top 80%",
                scrub: false,
                markers: false
            }})
            .addLabel('start', 0)
            .to(word2, {
                duration: 1,
                scaleY: 1,
                stagger: {
                    amount: 0.2,
                    from: 'start'
                }
            }, "start")          

            .to(word, {
                duration: 1,
                scaleY: 1,
                stagger: {
                    amount: 0.2,
                    from: 'start'
                }
            }, 'start')
    }

};


new switchTexts();